@tailwind base;
@tailwind components;
@tailwind utilities;

/* To hide scrollbar */
@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #EEF2FF;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1A7DBA;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #005183;
}

.ql-editor a {
    color: blue !important;
    text-decoration: underline !important;
}

.ql-editor a:visited {
    color: purple;
}